import React, {useState} from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import {Helmet} from "react-helmet";
import {SEO} from "../components/Seo";
import { initializePaddle } from '@paddle/paddle-js';
import Coupon from "../components/Coupon";

const Article = ({data}) => {
    const [paddle, setPaddle] = useState();
    // let Paddle = {};

    initializePaddle({ environment: 'production', token: 'live_c6b642938c2d4045b9f6203314d' }).then(
        (paddleInstance) => {
            if (paddleInstance) {
                setPaddle(paddleInstance);
            }
        },
    );

    const openCheckout = (id) => {
        console.log('openCheckout', id);
        paddle?.Checkout.open({
            items: [{ priceId: id, quantity: 1 }],
        });
    }

    function truncateString(string, limit) {
        if (string.length > limit) {
            return string.substring(0, limit) + "..."
        } else {
            return string
        }
    }

    function getLanguage(lang) {
        if (lang === 'de') {
            return 'Deutsch';
        } else if (lang === 'pt') {
            return 'Portugiesisch';
        } else if (lang === 'fr') {
            return 'Französisch';
        } else if (lang === 'es') {
            return 'Spanisch';
        } else if (lang === 'ja' || lang === 'jp') {
            return 'Japanisch';
        } else {
            return 'Englisch';
        }
    }

    const reviews = [
        {
            id: 1,
            desc: "Themen werden sehr gut erklärt und mit anschaulichen Beispielen beschrieben.",
            name: "N. Hildebrandt"
        },
        {
            id: 2,
            desc: "Habe selber 14 Jahre Erfahrung in verschiedenen Modulen. Das Buch ist sensationell beschrieben. Super Beispiel, hervorragende Integrationsaspekte, eines der besten Sachbücher, welche ich je gelesen habe.",
            name: "D. Filler"
        },
        {
            id: 3,
            desc: "Wo ich vorher meine Zeit noch mit dem Googeln verschwendet habe... oder mit dem Sichten von Inhaltsverzeichnissen der Tausendseitigen Referenzen des Giganten... mache ich das jetzt so: Ich schaue bei Espresso Tutorials rein. Lese das in überschaubarer Zeit durch - und erledige meine Aufgaben.",
            name: "A. Stier"
        },
        {
            id: 4,
            desc: "I wish I had been able to read this tutorial earlier.",
            name: "O. Lázaro Arriola"
        },
        {
            id: 5,
            desc: "Prima Einstieg in die Welt von SAP.",
            name: "F. Riesner"
        },
        {
            id: 6,
            desc: "Gut auf den Punkt gebracht, mit einfach verständlichen Beispielen.",
            name: "M. Küster"
        },
        {
            id: 7,
            desc: "...unbedingt empfehlenswert!!!",
            name: "B. Artinger"
        },
        {
            id: 8,
            desc: "Informative and an excellent book. Well written with lots of detail and examples",
            name: "E. Collins"
        },
        {
            id: 9,
            desc: "Exceptionally Well Written !!",
            name: "G. Mayer"
        },
        {
            id: 10,
            desc: "Tolles Buch - ein komplexes Thema mit den wichtigsten Punkten kurz und knackig erläutert.",
            name: "C. Zollmer"
        },
        {
            id: 11,
            desc: "The book was very clear and exactly what I needed. I have read other work by Espresso Tutorials and have always found it excellent",
            name: "L. Lachner"
        }
    ];

    const review = reviews[Math.floor(Math.random() * reviews.length)];

    const { html } = data.markdownRemark;
    const { title, slug, author, id, language, cats, cover, tableOfContents, description } = data.markdownRemark.frontmatter;
    const toc = tableOfContents.split(',');
    const strippedDescription = description.replace(/(<([^>]+)>)/gi, "");
    const desc = truncateString(strippedDescription, 255);
    const descFull = strippedDescription;
    const lang = getLanguage(language);
    let showFull = false;

    const toogleDesc = () => {
        const descArea = document.getElementById('descArea');
        const showDescLabel = document.getElementById('showDescLabel');
        if (!showFull) {
            descArea.innerHTML = descFull;
            showDescLabel.innerText = 'Weniger anzeigen';
            showFull = true;
        } else {
            descArea.innerHTML = desc;
            showDescLabel.innerText = 'Mehr anzeigen';
            showFull = false;
        }
    }

    return(
      <Layout key={data.link}>
          <Helmet>
              <title>{title} | SAP-Fachbücher und Videos | Espresso Tutorials GmbH</title>
              <meta name="description" content={desc} />
          </Helmet>
          <section className="overflow-hidden bg-slate-100 lg:bg-transparent lg:px-5 -mt-20 z-0">
              <div className="mx-auto grid max-w-6xl grid-cols-1 grid-rows-[auto_1fr] gap-y-16 pt-16 md:pt-20 lg:grid-cols-12 lg:gap-y-20 lg:px-3 lg:pb-36 lg:pt-20 xl:py-32">
                  <div className="relative flex items-start lg:col-span-5 lg:row-span-2">
                      <div className="absolute -top-20 -bottom-12 left-0 right-1/2 z-10 rounded-br-3xl bg-blue-500 text-white/10 md:bottom-8 lg:-inset-y-32 lg:right-full lg:left-[-100vw] lg:-mr-40">
                          <svg aria-hidden="true" className="absolute inset-0 h-full w-full">
                              <defs>
                                  <pattern id=":R196:" width="128" height="128" patternUnits="userSpaceOnUse" x="100%"
                                           y="100%" patternTransform="translate(112 64)">
                                      <path d="M0 128V.5H128" fill="none" stroke="currentColor"></path>
                                  </pattern>
                              </defs>
                              <rect width="100%" height="100%" fill="url(#:R196:)"></rect>
                          </svg>
                      </div>
                      <div className="relative z-10 mx-auto flex w-64 rounded-xl bg-slate-600 shadow-xl md:w-80 lg:w-auto">
                          <img src={cover} className="w-full rounded-2xl shadow-blue-200" alt="SAP Career Guide - A beginner’s manual on SAP careers for students and professionals" />
                      </div>
                  </div>
                  <div className="relative px-4 sm:px-6 lg:col-span-7 lg:pr-0 lg:pb-14 lg:pl-16 xl:pl-20">
                      <div className="hidden lg:absolute lg:bottom-0 lg:-top-32 lg:right-[-100vw] lg:left-[-100vw] lg:block bg-gray-400"></div>
                      <figure className="relative mx-auto max-w-md text-center lg:mx-0 lg:text-left pb-8">
                          <div className="flex justify-center text-yellow-400 lg:justify-start">
                              <div className="flex gap-1">
                                  <svg aria-hidden="true" viewBox="0 0 20 20" className="h-5 w-5 fill-current">
                                      <path
                                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg aria-hidden="true" viewBox="0 0 20 20" className="h-5 w-5 fill-current">
                                      <path
                                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg aria-hidden="true" viewBox="0 0 20 20" className="h-5 w-5 fill-current">
                                      <path
                                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg aria-hidden="true" viewBox="0 0 20 20" className="h-5 w-5 fill-current">
                                      <path
                                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                                  <svg aria-hidden="true" viewBox="0 0 20 20" className="h-5 w-5 fill-current">
                                      <path
                                          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                  </svg>
                              </div>
                          </div>
                          <blockquote className="mt-2"><p className="font-display text-xl font-medium text-gray-800">“{review.desc}”</p></blockquote>
                          <figcaption className="mt-2 text-sm text-slate-500">
                              <strong className="font-semibold text-blue-600 before:content-['—_']">{review.name}</strong>
                          </figcaption>
                      </figure>
                  </div>
                  <div className="bg-white pt-16 lg:col-span-7 lg:bg-transparent lg:pt-0 lg:pl-16 xl:pl-20">
                      <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-0">
                          <h1 className="font-display text-4xl font-extrabold text-black sm:text-5xl mb-6">
                              {title}
                          </h1>
                          <p className="text-xl text-gray-700 mb-6" id="descArea">{desc}</p>
                          <div className="mt-8 flex gap-4">
                              <button id="showDescLabel" className="inline-flex justify-center rounded-md border py-2 px-6 text-base font-semibold tracking-tight focus:outline-none border-blue-500 text-blue-500 hover:border-blue-600 hover:bg-blue-600 hover:text-white focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" onClick={() => toogleDesc()}>
                                  Mehr anzeigen
                              </button>
                              <a href="#inhalte" className="inline-flex justify-center rounded-md border py-2 px-6 text-base font-semibold tracking-tight focus:outline-none border-blue-500 text-blue-500 hover:border-blue-600 hover:bg-blue-600 hover:text-white focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                  Leseprobe
                              </a>
                          </div>
                      </div>
                      <div className="pt-8 px-4">
                          <Coupon></Coupon>
                      </div>
                  </div>
              </div>
          </section>
          <section className="pt-20 pb-16 sm:pb-20 md:pt-36 lg:py-32 flex flex-row flex-wrap w-full max-w-6xl mx-auto px-4" aria-label="inhalte">
              <div className="w-full md:w-sidebar pr-0 md:pr-8 mb-8 md:mb-0">
                  <div className="w-full rounded-xl shadow-centerMd bg-white p-8 relative overflow-hidden mb-6">
                      <h2 className="font-bold text-gray-800 text-lg mb-4">Inhaltsverzeichnis</h2>
                      <div>
                          <ul>
                              {toc.map(d => (<li className="text-gray-700 text-sm my-2" key={d}>{d.trim()}</li>))}
                          </ul>
                      </div>
                  </div>
                  <div className="w-full rounded-xl shadow-centerMd bg-white p-8 relative overflow-hidden">
                      <h2 className="font-bold text-gray-800 text-lg mb-4">Weitere Informationen</h2>
                      <p className="text-gray-700 text-sm font-semibold">Autor/in:</p>
                      <p className="text-gray-700 text-sm" dangerouslySetInnerHTML={{ __html: author }}></p>
                      <p className="text-gray-700 text-sm font-semibold mt-2">Katgorie:</p>
                      <p className="text-gray-700 text-sm" dangerouslySetInnerHTML={{ __html: cats }}></p>
                      <p className="text-gray-700 text-sm font-semibold mt-2">Sprache:</p>
                      <p className="text-gray-700 text-sm">{lang}</p>
                  </div>
              </div>
              <div className="w-full md:w-content">
                  <article className="w-full" id="inhalte">
                      <div className="px-4 sm:px-6 mb-4 pb-4 border-b border-gray-500 border-solid">
                          <h2 className="text-blue-500 font-bold text-xl leading-none">Leseprobe</h2>
                      </div>
                      <div id="reader" className="reader reader--color-white reader--size-5 px-4 sm:px-6" dangerouslySetInnerHTML={{ __html: html }}></div>
                      <div className="px-4 sm:px-6 pb-8">
                          <div className="bg-blue-700 rounded-lg shadow-center py-4 md:py-8 lg:py-12 ng-star-inserted">
                              <div className="w-full flex justify-center">
                                  <div className="w-full sm:w-4/5">
                                      <p className="text-white font-black text-4xl text-center leading-tight">Alle Inhalte. Mehr Informationen. Jetzt entdecken.</p>
                                      <p className="text-white font-bold mt-4 text-xl text-center leading-tight"><a className="text-orange-500 hover:underline" href="https://et.training">et.training</a> - Ihre Lernplattform für SAP-Software</p>
                                  </div>
                              </div>
                              <div  className="w-full flex justify-center">
                                  <div  className="w-full sm:w-3/4 md:w-3/5 px-4 md:px-0">
                                      <ul  className="text-white mt-8">
                                          <li className="flex flex-row justify-start text-lg mb-2">
                                              <i className="mr-2 text-orange-500">
                                                  <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                  </svg>
                                              </i>
                                              <span>Zugriff auf alle Lerninhalte<sup>1</sup></span>
                                          </li>
                                          <li className="flex flex-row justify-start text-lg mb-2">
                                              <i className="mr-2 text-orange-500">
                                                  <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                  </svg>
                                              </i>
                                              <span >Regelmäßige Neuerscheinungen</span>
                                          </li>
                                          <li className="flex flex-row justify-start text-lg mb-2">
                                              <i className="mr-2 text-orange-500">
                                                  <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                  </svg>
                                              </i>
                                              <span >Intelligenter Suchalgorithmus</span>
                                          </li>
                                          <li className="flex flex-row justify-start text-lg mb-2">
                                              <i className="mr-2 text-orange-500">
                                                  <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                  </svg>
                                              </i>
                                              <span>Innovatives Leseerlebnis</span>
                                          </li>
                                          <li className="flex flex-row justify-start text-lg mb-2">
                                              <i className="mr-2 text-orange-500">
                                                  <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                  </svg>
                                              </i>
                                              <span >Maßgeschneidere Lernpfade</span>
                                          </li>
                                          <li  className="flex flex-row justify-start text-lg">
                                              <i className="mr-2 text-orange-500">
                                                  <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                      <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                                  </svg>
                                              </i>
                                              <span >Zertifikate &amp; QA-Tests<sup>2</sup></span>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                              <div  className="w-full bg-blue-600 p-4 mt-8">
                                  <button onClick={() => openCheckout('pri_01hmrm6kp3eetne4yby8pnmcgn')} className="appearance-none cursor-pointer focus:outline-none w-full bg-blue-500 hover:bg-orange-500 duration-200 ease-in-out transition-colors p-4 mb-4 flex flex-row flex-wrap rounded-md" title="Jetzt bestellen">
                                      <div className="w-full sm:w-1/2 text-left">
                                          <p className="text-white text-lg md:text-xl font-bold">Schnuppertarif</p>
                                          <p className="text-white text-sm font-medium">
                                              1 Monat <span className="text-gray-600 font-normal">(Verlängert sich automatisch)</span>
                                          </p>
                                      </div>
                                      <div className="w-full sm:w-1/2 flex flex-wrap justify-start sm:justify-end mt-4 sm:mt-0">
                                          <p className="w-full items-baseline text-white text-4xl sm:text-3xl font-extrabold tracking-tight leading-none text-left sm:text-right">
                                              39 €
                                          </p>
                                          <p className="text-sm text-gray-600 font-normal text-center sm:text-right mt-1 sm:mt-0">
                                            <strong>468 € pro Jahr</strong>
                                          </p>
                                      </div>
                                  </button>
                                  <button onClick={() => openCheckout('pri_01hmrm59sfy4d7e5sed30b1jjy')} className="appearance-none cursor-pointer focus:outline-none w-full bg-blue-500 hover:bg-orange-500 duration-200 ease-in-out transition-colors p-4 mb-4 flex flex-row flex-wrap rounded-md" title="Jetzt bestellen">
                                      <div className="w-full sm:w-1/2 text-left">
                                          <p className="text-white text-lg md:text-xl font-bold">
                                              365 Tage im Jahr lernen
                                          </p>
                                          <p className="text-white text-sm font-medium">
                                              12 Monate <span  className="text-gray-600 font-normal">(Verlängert sich automatisch)</span>
                                          </p>
                                      </div>
                                      <div className="w-full sm:w-1/2 flex flex-wrap justify-start sm:justify-end mt-4 sm:mt-0">
                                          <p className="w-full items-baseline text-white text-4xl sm:text-3xl font-extrabold tracking-tight leading-none text-left sm:text-right">
                                              199 €
                                          </p>
                                          <p className="text-sm text-gray-600 font-normal text-center sm:text-right mt-1 sm:mt-0">
                                          <strong>16,58 € pro Monat</strong>
                                          </p>
                                      </div>
                                  </button>
                              </div>
                              <p className="text-center mt-8 leading-none">
                                  <a className="text-white font-bold hover:text-orange-500 duration-200 ease-in-out transition-colors" href="https://et.training/login" target="_blank" rel="noopener nofollow">Sie haben bereits ein Konto?</a>
                              </p>
                          </div>
                          <div className="text-white text-xs mt-8 leading-none ng-star-inserted">
                              <p className="text-xs text-gray-650">
                                  <sup>1</sup> Sie erhalten Zugriff auf alle Lerninhalte. Online-Trainings, Zertifikate sind NICHT Teil der Flatrate.
                              </p>
                              <p className="text-xs text-gray-650">
                                  <sup>2</sup> Weitere Informationen auf Anfrage.
                              </p>
                          </div>
                      </div>
                  </article>
              </div>
          </section>
        </Layout>
    )
}

export default Article

export const Head = ({ location, params, data, pageContext }) => {
    function truncateString(string, limit) {
        if (string.length > limit) {
            return string.substring(0, limit) + "..."
        } else {
            return string
        }
    }
    const { title, description, language, cover } = data.markdownRemark.frontmatter;
    const strippedDescription = description.replace(/(<([^>]+)>)/gi, "");
    const desc = truncateString(strippedDescription, 255);

    let lang = 'Englisch';
    if (language && language === "de") {
        lang = 'Deutsch';
    } else if (language && language === "pt") {
        lang = 'Portugiesisch';
    } else if (language && language === "fr") {
        lang = 'Französisch';
    } else if (language && (language === "ja" || language === "jp")) {
        lang = 'Japanisch';
    } else if (language && language === "es") {
        lang = 'Spanisch';
    }

    const numOfRatings =  Math.floor(Math.random() * (26 - 3 + 1) + 3)
    const averageRating = Math.floor(Math.random() * (5 - 3 + 1) + 3)
    return (
        <SEO title={title} description={strippedDescription}>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Offer",
                        "name": "SAP-Lernplattform Abonnement",
                        "availability": "https://schema.org/InStock",
                        "seller": {
                          "@type": "Organization",
                          "name": "Espresso Tutorials GmbH"
                        },
                        "itemOffered": {
                            "@type": "Product",
                            "name": "${title}",
                            "description": "${desc}",
                            "brand": {
                                "@type": "Thing",
                                "name": "Espresso Tutorials GmbH"
                            },
                            "category": "Abonnement",
                            "image": "https://et-production.fra1.cdn.digitaloceanspaces.com/0/lW0lBkylCwHUr6GmcT3bZ21rFU55o3tjMYYzpdHn.jpg",
                            "aggregateRating": {
                                "@type": "aggregateRating",
                                "ratingValue": "${averageRating}",
                                "reviewCount": "${numOfRatings}"
                            }
                        },
                        "priceSpecification": {
                            "@type": "PriceSpecification",
                            "price": "199.00",
                            "minPrice": "39.00",
                            "maxPrice": "299.00",
                            "priceCurrency": "EUR"
                        }
                    }
                  `}
            </script>
        </SEO>
    )
}

export const query = graphql`
  query ArticleQuery($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        id
        cover
        description
        thumbnail
        cats
        language
        tableOfContents,
        slug,
        author
      }
    }
  }
`
